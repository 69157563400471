import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import { staticPage } from "../../../config/staticPage";
import PurchaseOrdersListing from "../../components/account-bulk-orders/purchase-orders/purchase-orders-listing.";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";

const CompletedOrders = ({ location }) => {
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!user) {
      navigate(`/login?redirect_to=${location?.pathname}`);
    }
  }, []);

  if (!user) {
    return null;
  } else {
    return (
      <PurchaseOrdersListing
        location={location}
        status={"completed"}
        path={"/purchase-orders/completed"}
      />
    );
  }
};

export const Head = () => {
  const login_nav = staticPage.find((obj) => obj.name === "completed-orders");
  return (
    <>
      <title>{login_nav?.title}</title>
      <meta name="description" content={login_nav?.description} />
    </>
  );
};

CompletedOrders.Layout = Layout;

export default CompletedOrders;
